import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()

  return (
    <Layout {...props}>
      <Seo title='Our Team' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Team Members'
          subheader='We are proud to have a team of talented individuals who are dedicated to bringing you the latest and most accurate news from around the world. Our team members have diverse backgrounds and expertise, which helps us cover a wide range of topics and perspectives. We founded the company with the vision of creating a platform that would provide users with a comprehensive and unbiased view of the world. With years of experience in the industry, we hope you enjoy our passion for journalism and a commitment to quality.'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map((author, i) => (
            <React.Fragment key={`item-${i}`}>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </React.Fragment>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
